<template>
  <div>
    <!-- Page Heading -->
    <v-toolbar flat>
      <pageHeading pageTitle="Trips Report" />
      <v-spacer></v-spacer>
      <!-- <v-btn class="mx-2" depressed dark color="primary" @click="()=>$router.push('/approve_drivers/edit')">
          <v-icon left dark>mdi-plus-circle</v-icon> Add
        </v-btn> -->
    </v-toolbar>
    <v-divider></v-divider>
    <div class="content-wrap">
      <v-toolbar class="search-tools pa-0 mb-10 mt-5" flat>
        <!-- Action Button -->
        <!-- <v-spacer></v-spacer> -->
        <v-row>
          <v-col class="d-flex pl-0" cols="2" sm="2">
            <v-text-field
              label="Trip No"
              v-model="options.filter.trip_name"
              hide-details
              prepend-inner-icon="mdi-magnify"
              class="hidden-sm-and-down"
              clearable
              @keyup.native.enter="getDataFromApi"
            />
          </v-col>
          <v-col class="d-flex pl-0" cols="2" sm="2">
            <v-text-field
              label="Order No"
              v-model="options.filter.order_no"
              hide-details
              prepend-inner-icon="mdi-magnify"
              class="hidden-sm-and-down"
              clearable
              @keyup.native.enter="getDataFromApi"
            />
          </v-col>
          <v-col class="d-flex pl-0" cols="2" sm="2">
            <v-text-field
              label="Driver No"
              v-model="options.filter.employee_no"
              hide-details
              prepend-inner-icon="mdi-magnify"
              class="hidden-sm-and-down"
              clearable
              @keyup.native.enter="getDataFromApi"
            />
          </v-col>
          <v-col class="d-flex pl-0" cols="2" sm="2">
            <v-select
              :items="trip_cases"
              label="Case"
              v-model="options.filter.case"
              no-data-text="Select.."
              item-text="text"
              item-value="value"
              clearable
              hide-details
            ></v-select>
          </v-col>
          <v-col class="d-flex pl-0" cols="2" sm="2">
            <v-autocomplete
              label="Order Store"
              v-model="options.filter.order_store_uid"
              :items="rb_store_array_filtered"
              item-text="store_alias_id"
              item-value="store_uid"
              hide-details
              prepend-inner-icon="mdi-magnify"
              class="hidden-sm-and-down"
            />
          </v-col>
          <v-col class="d-flex px-0" cols="2" sm="2">
            <v-autocomplete
              label="Rider Store"
              v-model="options.filter.employee_store_uid"
              :items="rb_store_array"
              item-text="store_alias_id"
              item-value="store_uid"
              hide-details
              prepend-inner-icon="mdi-magnify"
              class="hidden-sm-and-down"
            />
          </v-col>
          <v-col class="d-flex px-0" cols="2" sm="2">
            <v-select
              v-model="options.filter.ln_status"
              :items="lnHookStatus"
              item-text="value"
              item-value="value"
              label="Order Status"
              multiple
              :single-line="true"
              height="32px"
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggle_multi_select">
                  <v-list-item-action>
                    <v-icon
                      :color="
                        options.filter.ln_status.length > 0
                          ? 'indigo darken-4'
                          : ''
                      "
                      >{{ selectall_icon }}</v-icon
                    >
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Select All</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-col>
          <v-col class="d-flex" cols="1" sm="1">
            <v-select
              :items="op_fleet_name"
              label="Fleet"
              v-model="options.filter.fleet_name"
              no-data-text="Select.."
              item-text="text"
              item-value="value"
              clearable
              hide-details
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="2" sm="2">
            <v-select
              v-model="options.filter.employee_tier_id"
              :items="op_employee_tier_id"
              item-text="text"
              item-value="value"
              label="Employee Tier"
              multiple
              :single-line="true"
              height="32px"
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggle_multi_select_emtier">
                  <v-list-item-action>
                    <v-icon
                      :color="
                        options.filter.employee_tier_id.length > 0
                          ? 'indigo darken-4'
                          : ''
                      "
                      >{{ selectall_emtier_icon }}</v-icon
                    >
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Select All</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-col>
          <v-col class="d-flex" cols="2" sm="2">
            <v-select
              :items="wallet_codes"
              label="Wallet Type"
              v-model="options.filter.wallet_code"
              no-data-text="Select.."
              item-text="text"
              item-value="value"
              clearable
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="3" sm="3">
            <date-picker
              class="mr-3"
              v-model="options.filter.datetime_range"
              type="datetime"
              :time-picker-options="datetimeConfig"
              format="DD-MM-YYYY HH:mm"
              value-type="DD-MM-YYYY HH:mm:ss"
              :placeholder="datetimePlaceholder"
              with="100%"
              range
              clearable
              confirm
            >
            </date-picker>
          </v-col>

          <!-- <v-spacer></v-spacer> -->
          <v-col cols="2" sm="2">
            <v-btn
              class="mb-1 mx-2"
              depressed
              small
              color="primary"
              @click="
                options.page = 1;
                getTripsReport(options);
              "
              :disabled="!allowSearch"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              class="mb-1 mx-2"
              depressed
              dark
              small
              color="primary"
              @click="exportTripsReport(options)"
            >
              <v-icon>mdi-export</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="dataLisings"
        item-key="id"
        :single-expand="true"
        :options.sync="options"
        :server-items-length="totalResult"
        :loading="loadingDataTable"
        multi-sort
        :footer-props="{
          'items-per-page-options': [25, 100, 500],
        }"
        class="text-small trips"
        show-expand
        @item-expanded="loadTransTickets"
      >
        <template v-slot:[`item.order_no`]="{ item }">
          <v-chip
            color="#BDFABD"
            small
            v-if="
              item.employee_tier_id == tpc_tier_id &&
                item.store_uid != item.employee_store_uid &&
                item.employee_store_uid != null &&
                user_type == 'REDBOOK'
            "
            label
          >
            {{ item.order_no }}
          </v-chip>
          <v-chip
            color="#FFFFFF"
            small
            v-if="
              item.employee_tier_id == tpc_tier_id &&
                item.store_uid == item.employee_store_uid &&
                user_type == 'REDBOOK'
            "
            label
          >
            {{ item.order_no }}
          </v-chip>
          <v-chip
            color="#EBBEFD"
            small
            v-if="
              item.employee_tier_id != tpc_tier_id && user_type == 'REDBOOK'
            "
            label
          >
            {{ item.order_no }}
          </v-chip>
          <span v-if="user_type == 'DM'">{{ item.order_no }}</span>
        </template>

        <template v-slot:[`item.employee_name`]="{ item }">
          <router-link
            v-if="item.employee_id"
            :to="{ name: 'DriversDetails', params: { id: item.employee_id } }"
            >{{ item.employee_name }}</router-link
          >
          <br v-if="item.employee_no && item.employee_id" />{{
            item.employee_no
          }}
        </template>
        <template v-slot:[`item.distance`]="{ value }">
          {{ value | comma }}
        </template>
        <template v-slot:[`item.total_amount`]="{ value }">
          {{ value | comma }}
        </template>
        <template v-slot:[`item.order_created_at`]="{ value }">
          {{ value | datetime }}
        </template>
        <template v-slot:[`item.webhook_updated_at`]="{ value }">
          <span v-if="value">{{ value | datetime }}</span>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn class="ma-1" outlined color="primary" icon tile small>
            <v-icon
              small
              @click="manualOrderTopupNew(item.order_no, item.employee_id)"
              >mdi-wallet-plus</v-icon
            >
          </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row class="expand-info">
              <v-col cols="3" sm="3">
                <h3 class="mb-1">Order Details</h3>
                <div>
                  <span class="info-title">Channel</span
                  ><span class="info-detail">{{ item.channel }}</span>
                </div>
                <div>
                  <span class="info-title">Gross Amount (bht)</span
                  ><span class="info-detail">{{
                    item.gross_amount | comma
                  }}</span>
                </div>
                <div>
                  <span class="info-title">Discount Amount (bht)</span
                  ><span class="info-detail">{{
                    item.discount_amount | comma
                  }}</span>
                </div>
                <div>
                  <span class="info-title">Voucher Amount (bht)</span
                  ><span class="info-detail">{{
                    item.voucher_deduct_food || 0
                  }}</span>
                </div>
                <div>
                  <span class="info-title">Voucher Type</span
                  ><span class="info-detail">{{
                    item.voucher_type || '-'
                  }}</span>
                </div>
                <div>
                  <span class="info-title">Delivery Original (bht)</span
                  ><span class="info-detail">{{
                    item.customer_delivery_fee | comma
                  }}</span>
                </div>
                <div>
                  <span class="info-title">Delivery Discount (bht)</span
                  ><span class="info-detail">{{
                    item.discount_delivery_total | comma
                  }}</span>
                </div>
                <div>
                  <span class="info-title">Delivery Fee (bht)</span
                  ><span class="info-detail">{{
                    item.delivery_fee | comma
                  }}</span>
                </div>
                <div>
                  <span class="info-title">Total Amount (bht)</span
                  ><span class="info-detail">{{
                    item.total_amount | comma
                  }}</span>
                </div>
              </v-col>
              <v-col
                cols="3"
                sm="3"
                v-if="
                  item.fleet_name != 'PANDAGO' &&
                    item.fleet_name != 'GRAB' &&
                    item.fleet_name != 'ZEEKAGG'
                "
              >
                <h3 class="mb-1">Driver Payment</h3>
                <div>
                  <span class="info-title">Driver Distance (m)</span
                  ><span class="info-detail">{{ item.distance | comma }}</span>
                </div>
                <div>
                  <span class="info-title">Driver Delivery Fee (bht)</span
                  ><span class="info-detail">{{
                    item.driver_delivery_fee | comma
                  }}</span>
                </div>
                <div>
                  <span class="info-title">Driver Getpaid Food (bht)</span
                  ><span class="info-detail">{{
                    item.driver_getpaid_food | comma
                  }}</span>
                </div>
                <div>
                  <span class="info-title">Driver Getpaid Delivery (bht)</span
                  ><span class="info-detail">{{
                    item.driver_getpaid_delivery | comma
                  }}</span>
                </div>
                <div>
                  <span class="info-title">Commission (bht)</span
                  ><span class="info-detail">{{
                    item.commission | comma
                  }}</span>
                </div>
                <div>
                  <span class="info-title">Topup Food (bht)</span
                  ><span class="info-detail">{{
                    item.topup_food | comma
                  }}</span>
                </div>
                <div>
                  <span class="info-title">Topup Delivery (bht)</span
                  ><span class="info-detail">{{
                    item.topup_delivery | comma
                  }}</span>
                </div>
              </v-col>
              <v-col cols="6" sm="6">
                <!-- Store -->
                <template
                  v-if="
                    item.employee_store_uid &&
                      rb_stores &&
                      rb_stores[item.employee_store_uid]
                  "
                >
                  <h3 class="">
                    Rider Store ::
                    {{ rb_stores[item.employee_store_uid].store_alias_id }}
                    {{ rb_stores[item.employee_store_uid].name_th }}
                  </h3>
                </template>
                <template
                  v-if="
                    item.store_uid && rb_stores && rb_stores[item.store_uid]
                  "
                >
                  <h3 class="">
                    Order Store ::
                    {{ rb_stores[item.store_uid].store_alias_id }}
                  </h3>
                  <div v-if="rb_stores && rb_stores[item.store_uid]">
                    {{ rb_stores[item.store_uid].name_th }}, Manage :
                    {{ rb_stores[item.store_uid].phone_manager }}, Store :
                    {{ rb_stores[item.store_uid].phone_store }}
                  </div>
                  <div v-if="rb_stores && rb_stores[item.store_uid]">
                    {{ rb_stores[item.store_uid].delivery_zone }}
                  </div>
                  <v-btn
                    v-if="
                      item.employee_tier_error_type != null &&
                        [
                          'DELIVEREDNOTIFICATION',
                          'NOTDELIVEREDNOTIFICATION',
                        ].includes(item.latest_ln_order_status) &&
                        (item.fleet_name == 'ZEEK' || item.fleet_name == 'MFG')
                    "
                    class="pl-1 pr-1"
                    color="primary"
                    small
                    @click="addZeekRiderInfo(item)"
                    >Update Rider Type</v-btn
                  >
                  <v-col
                    v-if="
                      item.latest_ln_order_status ==
                        'ORDERCREATIONNOTIFICATION' &&
                        manual_assign_store_list.includes(item.store_alias_id)
                    "
                    class="d-flex pl-0 pb-0"
                    cols="7"
                    sm="7"
                  >
                    <v-text-field
                      ref="rider_mobile"
                      type="tel"
                      :label="getAssignRiderLabel(item)"
                      v-model="assign_rider_mobile_no"
                      v-mask="'###-###-####'"
                      clearable
                      editable
                      @keyup.native.enter="checkManualAssignRider()"
                    />
                    <v-btn
                      class="mt-5 ml-2"
                      color="primary"
                      :loading="isCheckRiderPhoneLoading"
                      :disabled="isCheckRiderPhoneLoading"
                      small
                      @click="checkManualAssignRider()"
                    >
                      <v-icon>mdi-magnify</v-icon>
                      Find</v-btn
                    >
                  </v-col>
                  <v-col
                    v-if="searchRiderInfo"
                    class="d-flex pl-0 pt-0"
                    cols="7"
                    sm="7"
                  >
                    <v-text-field
                      v-model="assign_rider_name"
                      disabled
                      readonly
                    />
                    <v-btn
                      class="mt-5 ml-2"
                      color="primary"
                      :loading="isAssignRiderLoading"
                      :disabled="isAssignRiderLoading"
                      small
                      @click="checkManualAssignRider('Assign', item)"
                      >Assign</v-btn
                    >
                  </v-col>
                </template>
                <template v-if="item.channel == 'FAAS'">
                  <h3 class="">
                    Order Store :: {{ item.store_alias_id }}, Tel ::
                    {{ item.phone_store }}
                  </h3>
                  <div>
                    {{ item.store.name_th }}
                    <v-btn
                      v-if="
                        item.employee_tier_error_type != null &&
                          [
                            'DELIVEREDNOTIFICATION',
                            'NOTDELIVEREDNOTIFICATION',
                          ].includes(item.latest_ln_order_status) &&
                          (item.fleet_name == 'ZEEK' ||
                            item.fleet_name == 'MFG')
                      "
                      class="pl-1 pr-1"
                      color="primary"
                      small
                      @click="addZeekRiderInfo(item)"
                      >Update Rider Type</v-btn
                    >
                  </div>
                </template>
                <!-- Check Reconsign Button/ no transaction / delivered / required topup / not a tpc -->
                <template
                  v-if="
                    canDo.can.includes('check_payment') &&
                      item.transactions &&
                      item.transactions.length == 0 &&
                      item.latest_ln_order_status == 'DELIVEREDNOTIFICATION' &&
                      item.topup_delivery > 0 &&
                      checkTPCFR(item) == true
                  "
                >
                  <h3 class="mb-2">Error not payment</h3>
                  <div>
                    กรุณาตรวจสอบสถานะคนขับ และกดปุ่ม<v-btn
                      class="pl-1 pr-1"
                      small
                      @click="checkReconsileOrder(item.order_no)"
                      >ตรวจสอบ</v-btn
                    >
                  </div>
                </template>
                <!-- Transaction -->
                <template
                  v-if="item.transactions && item.transactions.length > 0"
                >
                  <h3 class="mb-2">Transactions</h3>
                  <v-simple-table class="custom-table1" width="600">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left py-1" width="200">
                            Transaction Type
                          </th>
                          <th class="text-center" width="100">Amount(Bht)</th>
                          <th class="text-center" width="100">Status</th>
                          <th class="text-left" width="200">Time</th>
                          <th class="text-left" width="200">Wallet</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="t in item.transactions" :key="t.id">
                          <td>{{ t.transaction_type }}</td>
                          <td class="text-center">{{ t.amount }}</td>
                          <td class="text-center">
                            <v-chip
                              v-if="t.tmn_transfer_status == 1"
                              color="green"
                              dark
                              x-small
                              >Success</v-chip
                            >
                            <v-chip
                              v-else-if="t.tmn_transfer_status == -1"
                              color="red darken-4"
                              dark
                              x-small
                              >Fail Verify</v-chip
                            >
                            <v-chip
                              v-else-if="t.tmn_transfer_status == -2"
                              color="red darken-4"
                              dark
                              x-small
                              >Fail Notify</v-chip
                            >
                          </td>
                          <td>{{ t.updatedAt | datetime }}</td>
                          <td>{{ t.wallet_code }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <div style="height: 15px; display:block;"></div>
                </template>
                <template v-if="item.tickets && item.tickets.length > 0">
                  <h3 class="mb-2">Manual Topup Tickets</h3>
                  <v-simple-table class="custom-table1" width="600">
                    <tbody>
                      <tr v-for="tk in item.tickets" :key="tk.id">
                        <td width="200" class="text-left">
                          <span>{{
                            tk.ticket_data
                              ? tk.ticket_data.topup_type
                              : tk.ticket_type
                          }}</span>
                          <span v-if="tk.ticket_id && !tk.ticket_link"
                            >[ #{{ tk.ticket_id }} ]</span
                          >
                          <a
                            :href="tk.ticket_link"
                            target="blank"
                            v-if="tk.ticket_id && tk.ticket_link"
                            >[ #{{ tk.ticket_id }} ]</a
                          >
                        </td>
                        <td width="100" class="text-center">
                          {{
                            tk.ticket_data
                              ? tk.ticket_data.topup_amount + ' Bht'
                              : ''
                          }}
                        </td>
                        <td width="100" class="text-center">
                          <v-chip
                            v-if="tk.is_approve == true"
                            color="green"
                            dark
                            x-small
                            >Approved</v-chip
                          >
                          <v-chip
                            v-else-if="tk.is_approve == false"
                            color="red darken-4"
                            dark
                            x-small
                            >Rejected</v-chip
                          >
                          <v-chip v-else color="grey lighten-2" dark x-small
                            >Pending</v-chip
                          >
                        </td>
                        <td width="200" class="text-left">
                          {{ tk.updatedAt | datetime }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </template>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>

      <!-- Manual Topup Modal -->
      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Manual Topup</span>
          </v-card-title>
          <v-card-text>
            <v-form v-model="form_valid" ref="form">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.order_no"
                      label="Order No"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.amount"
                      :rules="[validate_rules.required]"
                      type="number"
                      label="Amount"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-select
                      :items="orderTopupTransactionTypes"
                      label="Transaction type"
                      v-model="editedItem.transaction_type"
                      :rules="[validate_rules.required]"
                      no-data-text="Select.."
                      item-text="value"
                      item-value="value"
                      clearable
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      label="Note"
                      v-model="editedItem.note"
                      rows="3"
                      no-resize
                      clearable
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      label="SMS Message"
                      v-model="editedItem.sms"
                      rows="3"
                      no-resize
                      clearable
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="preview">Submit</v-btn>
          </v-card-actions>
        </v-card>
        <!-- End Modal Content -->
      </v-dialog>

      <!-- Manual Rider Assign Modal -->
      <v-dialog
        @click:outside="checkManualAssignRider('Cancel')"
        v-model="manual_assign_rider_dialog"
        max-width="512px"
      >
        <v-card>
          <v-card-title
            v-if="
              riderAvailability &&
                !assignRiderResponse &&
                riderAvailability.is_warning
            "
            class="d-flex flex-column align-center"
          >
            <v-icon color="info" size="44">mdi-alert-circle</v-icon>
            <span class="headline text-left">Rider already assigned</span>
          </v-card-title>

          <v-card-title
            v-if="assignRiderResponse && assignRiderResponse.success"
            class="d-flex flex-column align-center"
          >
            <v-icon color="success" size="44">mdi-check-circle</v-icon>
            <span class="headline text-left">Rider assign success</span>
          </v-card-title>

          <v-card-title
            v-if="assignRiderResponse && !assignRiderResponse.success"
            class="d-flex flex-column align-center"
          >
            <v-icon color="error" size="44">mdi-alert</v-icon>
            <span class="headline text-left">Rider assign error</span>
          </v-card-title>

          <v-card-text
            v-if="
              riderAvailability &&
                !assignRiderResponse &&
                riderAvailability.is_warning
            "
            class="px-10 pb-0"
          >
            คนขับรับงานอยู่แล้ว {{ riderAvailability.current_trip_count }} งาน
            หากต้องการจ่ายงานให้คนขับเบอร์ {{ assign_rider_mobile_no }} กด
            Submit เปลี่ยนคนขับใหม่ กด Edit
          </v-card-text>

          <v-card-text
            v-if="assignRiderResponse && assignRiderResponse.success"
            class="text-center pb-0"
          >
            จ่ายงานให้คนขับสำเร็จ
          </v-card-text>

          <div v-if="assignRiderResponse && !assignRiderResponse.success">
            <v-card-text class="text-center">
              ไม่สามารถจ่ายงานให้คนขับได้
              กรุณาตรวจสอบข้อมูลและทำรายการใหม่อีกครั้ง
            </v-card-text>
            <v-card-text class="text-sm-center pb-0">
              code: {{ assignRiderResponse.error.code }} | zeek_code:
              {{ assignRiderResponse.error.zeek_code }} <br />
              {{ assignRiderResponse.error.message }}
            </v-card-text>
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="assignRiderResponse && assignRiderResponse.success"
              color="blue darken-1"
              text
              @click="checkManualAssignRider('Done')"
              >Done</v-btn
            >
            <v-btn
              v-if="assignRiderResponse && !assignRiderResponse.success"
              color="blue darken-1"
              text
              @click="checkManualAssignRider('Edit')"
              >Close</v-btn
            >
            <v-btn
              v-if="
                riderAvailability &&
                  !assignRiderResponse &&
                  riderAvailability.is_warning
              "
              color="blue darken-1"
              text
              @click="checkManualAssignRider('Edit')"
              >Edit</v-btn
            >
            <v-btn
              v-if="
                riderAvailability &&
                  !assignRiderResponse &&
                  riderAvailability.is_warning
              "
              color="blue darken-1"
              text
              @click="checkManualAssignRider('Confirm')"
              >Submit</v-btn
            >
          </v-card-actions>
        </v-card>
        <!-- End Modal Content -->
      </v-dialog>

      <!-- Auto Payment Modal -->
      <v-dialog v-model="payment_dialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Manual Topup</span>
          </v-card-title>
          <v-card-text>
            <v-form v-model="form_valid_payment" ref="form_valid_payment">
              <v-container>
                <v-row>
                  <v-col cols="6" sm="6" md="6">
                    <v-text-field
                      v-model="editedPayment.assignee"
                      type="text"
                      label="Assignee"
                    ></v-text-field>
                    <v-text-field
                      v-model="editedPayment.form"
                      :rules="[validate_rules.required]"
                      type="text"
                      label="Form"
                    ></v-text-field>
                    <v-text-field
                      v-model="editedPayment.tags"
                      :rules="[validate_rules.required]"
                      type="text"
                      label="Tags"
                    ></v-text-field>
                    <v-text-field
                      v-model="editedPayment.order_no"
                      :rules="[validate_rules.required]"
                      type="text"
                      label="Order ID"
                    ></v-text-field>
                    <v-text-field
                      v-model="editedPayment.amount"
                      :rules="[validate_rules.required]"
                      type="number"
                      label="Amount"
                    >
                      <template #label>
                        Amount<span class="red--text"><strong> *</strong></span>
                      </template>
                    </v-text-field>
                    <v-text-field
                      v-model="editedPayment.remark"
                      :rules="[validate_rules.required]"
                      type="text"
                      label="Remark"
                    >
                      <template #label>
                        Remark<span class="red--text"><strong> *</strong></span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" sm="6" md="6">
                    <v-select
                      :items="subjectLists"
                      label="Subject"
                      v-model="editedPayment.subject"
                      :rules="[validate_rules.required]"
                      no-data-text="Select.."
                      item-text="text"
                      item-value="value"
                      clearable
                    ></v-select>
                    <v-textarea
                      label="Note"
                      v-model="editedPayment.note"
                      rows="3"
                      no-resize
                      clearable
                    ></v-textarea>
                    <v-textarea
                      label="SMS message"
                      v-model="editedPayment.sms"
                      rows="3"
                      no-resize
                      clearable
                    ></v-textarea>
                    <v-file-input
                      v-model="editedPayment.image[0].file_data"
                      :rules="rules_photo"
                      accept="image/png, image/jpeg, image/bmp"
                      placeholder="Pick an photo"
                      prepend-icon="mdi-camera"
                      label="Upload Photo"
                      v-on:change="changeImg(0)"
                    ></v-file-input>
                    <v-file-input
                      v-model="editedPayment.image[1].file_data"
                      :rules="rules_photo"
                      accept="image/png, image/jpeg, image/bmp"
                      placeholder="Pick an photo"
                      prepend-icon="mdi-camera"
                      label="Upload Photo"
                      v-on:change="changeImg(1)"
                    ></v-file-input>
                    <v-file-input
                      v-model="editedPayment.image[2].file_data"
                      :rules="rules_photo"
                      accept="image/png, image/jpeg, image/bmp"
                      placeholder="Pick an photo"
                      prepend-icon="mdi-camera"
                      label="Upload Photo"
                      v-on:change="changeImg(2)"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="payment_close"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="payment_save"
              >Submit</v-btn
            >
          </v-card-actions>
        </v-card>
        <!-- End Modal Content -->
      </v-dialog>

      <!-- Preview Dialog -->
      <v-dialog v-model="preview_dialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Are you sure to manual topup for:</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <strong>Order No:</strong> {{ editedItem.order_no }}
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <strong>Amount:</strong> {{ editedItem.amount }} Bht
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <strong>Transaction type:</strong>
                  {{ editedItem.transaction_type }}
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <strong>Note:</strong> {{ editedItem.note }}
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="editedItem.sms">
                  <strong>SMS Message:</strong> {{ editedItem.sms }}
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="preview_close"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="save">Confirm</v-btn>
          </v-card-actions>
        </v-card>
        <!-- End Modal Content -->
      </v-dialog>

      <!-- Preview Dialog -->
      <v-dialog v-model="update_zeek_dialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Update ZEEK rider info:</span>
          </v-card-title>

          <v-card-text>
            <v-form v-model="form_zeek_valid" ref="form">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <strong>Order No:</strong> {{ editedZEEK.order_no }}
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <strong>Name:</strong> {{ editedZEEK.name }}
                  </v-col>
                  <v-col cols="12" sm="4" md="4"
                    ><strong>current poisiton:</strong>
                    {{ current_position }}</v-col
                  >
                  <v-col cols="12" sm="4" md="4"
                    ><strong>skill set:</strong> {{ current_skillset }}</v-col
                  >
                  <v-col cols="12" sm="4" md="4"
                    ><strong>store:</strong> {{ store }}</v-col
                  >
                  <v-col cols="12" sm="12" md="12">
                    <v-select
                      :items="defaultZEEK.position_code"
                      label="Position code"
                      v-model="editedZEEK.position_code"
                      :rules="[validate_rules.required]"
                      no-data-text="Select.."
                      item-text="name"
                      item-value="value"
                      clearable
                      v-on:change="positionChange"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-select
                      :items="defaultZEEK.skill_set"
                      label="Skill set"
                      v-model="editedZEEK.skill_set"
                      :rules="[validate_rules.required]"
                      no-data-text="Select.."
                      item-text="name"
                      item-value="value"
                      clearable
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="update_zeek_close"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="saveZEEKInfo"
              >Confirm</v-btn
            >
          </v-card-actions>
        </v-card>
        <!-- End Modal Content -->
      </v-dialog>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios';
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'TripsReport',
  components: {
    pageHeading,
    DatePicker,
  },
  data: () => ({
    tpc_tier_id: null,
    tpc2_tier_id: null,
    tpcfl1_tier_id: null,
    tpcfl2_tier_id: null,
    user_type: null,
    rb_stores: null,
    rb_store_array: [],
    rb_store_array_filtered: [],
    dialog: false,
    preview_dialog: false,
    payment_dialog: false,
    update_zeek_dialog: false,
    manual_assign_rider_dialog: false,
    filterDatePicker: false,
    current_position: '',
    current_skillset: '',
    store: '',
    assign_rider_mobile_no: '',
    assign_rider_name: '',
    current_assign_item: null,
    manual_assign_store_list: [],
    editedItem: {
      order_no: '',
      amount: 0,
      transaction_type: null,
      note: '',
      sms: '',
    },
    defaultItem: {
      order_no: '',
      amount: 0,
      transaction_type: null,
      note: '',
      sms: '',
    },
    editedPayment: {
      assignee: 'Operations',
      form: 'Request For Manual Topup ticket',
      tags: 'Auto Payment',
      image: [
        {
          file_data: null,
          url_upload: null,
          path: null,
        },
        {
          file_data: null,
          url_upload: null,
          path: null,
        },
        {
          file_data: null,
          url_upload: null,
          path: null,
        },
      ],
      current_img: null,
      order_no: '',
      amount: 0,
      remark: '',
      subject: '',
      to: '',
      note: '',
      sms: '',
      employee_id: '',
    },
    defaultPayment: {
      assignee: 'Operations',
      form: 'Request For Manual Topup ticket',
      tags: 'Auto Payment',
      image: [
        {
          file_data: null,
          url_upload: null,
          path: null,
        },
        {
          file_data: null,
          url_upload: null,
          path: null,
        },
        {
          file_data: null,
          url_upload: null,
          path: null,
        },
      ],
      current_img: null,
      order_no: '',
      amount: 0,
      remark: '',
      subject: '',
      to: '',
      note: '',
      sms: '',
      employee_id: '',
    },
    subjectLists: [
      { value: '1', text: '[Driver] ค่าแท็กซี่ออเดอร์ใหญ่' },
      { value: '2', text: '[Driver] พนักงานขนส่งต้องการเคลมค่าที่จอดรถ' },
      {
        value: '3',
        text: '[Driver] พนักงานขนส่งได้รับค่าขนส่งไม่ถูกต้องตามระยะทาง',
      },
      { value: '4', text: 'Compensation - Cancel before pickup by Manual' },
      {
        value: '6',
        text: '[Driver] พนักงานขนส่งไม่ได้รับค่าขนส่งหลังจากเสร็จงาน',
      },
      {
        value: '7',
        text: '[Driver] พนักงานขนส่งไม่ได้รับค่าขนส่ง (Order ซ่อม)',
      },
      // { value: '8', text: 'Urgent FoodClaim' },
      { value: '9', text: 'Urgent FoodClaim AttempedDeliver' },
      {
        value: '10',
        text: '[Driver] พนักงานขนส่งต้องการเคลมค่าอาหาร (Food Claim)',
      },
      { value: '11', text: '[Driver] พนักงานส่ง (Order ซ่อม / Order ใหญ่)' },
    ],
    wallet_codes: [
      { value: 'TPC', text: 'TPC' },
      { value: '1112D', text: '1112D' },
    ],
    editedZEEK: {
      order_no: '',
      employee_id: '',
      skill_set: [],
      position_code: JSON.parse(process.env.VUE_APP_ZEEK_POSITIONCODE),
    },
    defaultZEEK: {
      order_no: '',
      employee_id: '',
      skill_set: [],
      position_code: JSON.parse(process.env.VUE_APP_ZEEK_POSITIONCODE),
    },
    options: {
      page: 1,
      itemsPerPage: 25,
      sortBy: ['order_created_at', 'order_no'],
      sortDesc: [true, false],
      multiSort: true,
      mustSort: true,
      filter: {
        trip_name: '',
        order_no: '',
        employee_no: '',
        ln_status: [],
        datetime_range: [],
        date_start: '',
        date_end: '',
        case: null,
        scope_store_rider: null,
        order_store_uid: null,
        employee_store_uid: null,
        scope_store_type: 'Order',
        employee_tier_id: [],
        wallet_code: null,
      },
    },
    datetimeConfig: {
      start: '00:00',
      step: '01:00',
      end: '23:00',
      format: 'HH:mm',
    },
    datetimePlaceholder: '',
    expanded: [],
    isSingleExpand: false,
    trip_cases: [{ value: 'select_fixed_order', text: 'Zk resend order' }],
    op_fleet_name: [
      { value: 'LOGINEXT', text: 'Loginext' },
      { value: 'ZEEK', text: 'Zeek' },
      { value: 'GRAB', text: 'Grab' },
      { value: 'PANDAGO', text: 'Panda Go' },
      { value: 'ZEEKAGG', text: 'ZEEKAGG' },
      { value: 'MFG', text: 'MFG' },
      { value: 'INSTORE', text: 'INSTORE' },
    ],
    op_employee_tier_id: [],
    form_valid: false,
    form_zeek_valid: false,
    form_valid_payment: false,
    validate_rules: {
      required: (v) => !!v || 'This field is required',
      select2: (v) => {
        if (typeof v === 'object') {
          return v.value !== '' || 'This field is required';
        } else {
          return true;
        }
      },
      // select: v => { return (v !== '') || 'This field is required' },
      number: (v) => /^[0-9]/.test(v) || 'Number only please',
    },
    rules_photo: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        'Avatar size should be less than 2 MB!',
    ],
  }),
  watch: {
    'options.sortBy': function(newVal, oldVal) {
      //to work with changes in someOtherProp
      if (newVal) {
        this.getTripsReport(this.options);
      }
    },
    dialog(val) {
      val || this.close();
    },
    filterDatePicker(val) {
      if (val && this.options.filter.date_range.length == 0) {
        this.options.filter.date_range = [
          moment()
            .subtract(0, 'days')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ];
      }
    },
  },
  filters: {
    trans_status(value) {
      return value == '0' ? 'success' : value;
    },
  },
  methods: {
    ...mapActions([
      'genCan',
      'getTripsReport',
      'loadTransTickets',
      'sendOrderManualTopup',
      'sendOrderManualTopupNew',
      'updateZEEKInfo',
      'getEmployeeTier',
      'getAllRedbookStore',
      'getTPCTierId',
      'getTPC2TierId',
      'getTPCFL1TierId',
      'getTPCFL2TierId',
      'exportTripsReport',
      'checkOrderReconsign',
      'attemptOrderReconsign',
      'getCurrentPosition',
      'getDriverDetail',
      'getUploadAWSUrl',
      'getRiderTaskInfo',
      'riderAssignPartner',
      'getCheckRiderWithPhoneNumber',
      'getCheckRiderAvailability',
      'assignRider',
      'resetRiderManualAssign',
    ]),
    checkReconsileOrder: async function(order_no) {
      let reconsign_record = await this.checkOrderReconsign({ order_no });
      if (!reconsign_record) {
        alert('เกิดข้อผิดพลาดโปรดทดลองใหม่อีกครั้ง');
      } else if (reconsign_record.length > 0) {
        alert(
          'Order นี้ถูกบันทึกว่าทำจ่ายแล้ว โปรดแจ้ง Admin เพื่อดำเนินการตรวจสอบ'
        );
      } else {
        if (
          confirm(
            'Order ยังไม่ถูกบันทึกว่าได้ทำจ่ายเงิน ต้องการทำจ่ายเงินหรือไม่?'
          )
        ) {
          let attemp_result = await this.attemptOrderReconsign({ order_no });
          if (attemp_result)
            alert(
              'ได้ส่งคำสั่งเพื่อทำจ่ายแล้ว โปรรอ 5 นาที และโหลดข้อมูลหน้านี้ใหม่'
            );
          else
            alert(
              'เกิดข้อผิดพลาด ไม่สามารถส่งคำสั่งเพื่อทำจ่ายได้ โปรดแจ้ง Admin เพื่อดำเนินการตรวจสอบ'
            );
        }
      }
    },
    prepareStoreData: async function() {
      const lodash = require('lodash');
      // console.log("Load redbook store")
      this.rb_store_array = await this.getAllRedbookStore({ load_full: true });
      this.rb_store_array_filtered = await this.getAllRedbookStore();
      this.rb_stores = lodash.keyBy(this.rb_store_array, 'store_uid');

      this.op_employee_tier_id = _.map(await this.getEmployeeTier(), (tier) => {
        return { value: tier.id, text: tier.name };
      });
    },
    getDataFromApi() {
      this.options.page = 1;
      this.getTripsReport(this.options);
    },
    clearFilterDaterange() {
      this.options.filter.date_range = [];
    },
    // loadTransTickets({item}){
    //     // axios.get('http.../' + item.id)
    //     // .then(response => {
    //     //   item.samples = response.data[0]
    //     // })
    //     item.transactions = []
    //     item.tickets = ['test']
    //     console.log(item.transactions, item.tickets)
    // },
    toggle_multi_select() {
      this.$nextTick(() => {
        if (this.selectedAll) {
          this.options.filter.ln_status = [];
        } else {
          this.options.filter.ln_status = this.lnHookStatus.slice();
        }
      });
    },
    toggle_multi_select_emtier() {
      this.$nextTick(() => {
        if (this.selectedAllEmtier) {
          this.options.filter.employee_tier_id = [];
        } else {
          this.options.filter.employee_tier_id = _.map(
            this.op_employee_tier_id,
            'value'
          );
        }
      });
    },
    manualOrderTopup(order_no) {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem.order_no = order_no;
      this.dialog = true;
    },
    manualOrderTopupNew(order_no, employee_id) {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedPayment.order_no = order_no;
      this.editedPayment.employee_id = employee_id;
      this.payment_dialog = true;
    },
    close() {
      this.dialog = false;
    },
    preview() {
      if (this.form_valid) {
        this.dialog = false;
        this.preview_dialog = true;
      }
    },
    preview_close() {
      this.preview_dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.$refs.form.resetValidation(); // prevent validate error show again when click add
      }, 300);
    },
    save() {
      this.sendOrderManualTopup(this.editedItem);
      this.preview_close();
    },
    payment_close() {
      this.payment_dialog = false;
      setTimeout(() => {
        this.editedPayment = Object.assign({}, this.defaultPayment);
        this.$refs.form_valid_payment.resetValidation(); // prevent validate error show again when click add
      }, 300);
    },
    payment_save() {
      if (this.$refs.form_valid_payment.validate()) {
        if (this.editedPayment.employee_id != null) {
          this.sendOrderManualTopupNew(this.editedPayment);
          this.payment_close();
        } else {
          alert('employee is not null');
          this.payment_close();
        }
      }
      // if(this.form_valid_payment){
      //   this.sendOrderManualTopupNew(this.editedPayment);
      //   this.payment_close()
      // }
    },
    initDatetime() {
      var now = moment();
      var roundEnd =
        now.minute() || now.second() || now.millisecond()
          ? now.add(1, 'hour').startOf('hour')
          : now.startOf('hour');
      var roundStart = roundEnd.clone().subtract(2, 'hours');
      console.log(roundStart.toDate(), roundEnd.toDate());
      this.options.filter.datetime_range = [
        roundStart.toDate(),
        roundEnd.toDate(),
      ];
      this.datetimePlaceholder =
        roundStart.format('DD-MM-YYYY HH:mm') +
        ' ~ ' +
        roundEnd.format('DD-MM-YYYY HH:mm');
      this.getTripsReport(this.options);
    },

    setInitFilterOptions() {
      this.options = {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['order_created_at', 'order_no'],
        sortDesc: [true, false],
        multiSort: true,
        mustSort: true,
        filter: {
          trip_name: '',
          order_no: '',
          employee_no: '',
          ln_status: [],
          datetime_range: [],
          date_start: '',
          date_end: '',
          case: null,
          scope_store_rider: null,
          order_store_uid: null,
          employee_store_uid: null,
          scope_store_type: 'Order',
          employee_tier_id: [],
          wallet_code: null,
        },
      };
    },

    async addZeekRiderInfo(item) {
      let orderno = item.order_no;
      const currentData = await this.getCurrentPosition(orderno);

      let partner = {};
      if (
        currentData.payload[currentData.payload.length - 1].fleet_raw_data !==
          null &&
        Object.hasOwn(
          currentData.payload[currentData.payload.length - 1],
          'fleet_raw_data'
        )
      ) {
        if (
          Object.hasOwn(
            currentData.payload[currentData.payload.length - 1].fleet_raw_data,
            'tasks'
          ) &&
          currentData.payload[currentData.payload.length - 1].fleet_raw_data
            .tasks[0]
        ) {
          partner =
            currentData.payload[currentData.payload.length - 1].fleet_raw_data
              .tasks[0].partner;
        }
      } else {
        if (
          Object.hasOwn(
            currentData.payload[currentData.payload.length - 1],
            'tasks'
          ) &&
          currentData.payload[currentData.payload.length - 1].tasks[0]
        ) {
          partner =
            currentData.payload[currentData.payload.length - 1].tasks[0]
              .partner;
        }
      }

      this.current_position = partner.position ? partner.position : '-';
      this.current_skillset = partner.role ? partner.role : '-';

      let empStore = '';
      if (item.employee_id !== null) {
        empStore = await this.getDriverDetail(item.employee_id);
      }

      this.store =
        empStore.store_alias_id != null
          ? empStore.store_alias_id.store_alias_id
          : '-';

      this.editedZEEK = Object.assign({}, this.defaultZEEK);
      // if(this.form_zeek_valid){
      this.editedZEEK.order_no = item.order_no;
      this.editedZEEK.employee_id = item.employee_id;
      this.editedZEEK.name = item.employee_name;
      this.dialog = false;
      this.preview_dialog = false;
      this.update_zeek_dialog = true;
      // }
    },
    update_zeek_close() {
      this.update_zeek_dialog = false;
      setTimeout(() => {
        this.editedZEEK = Object.assign({}, this.defaultZEEK);
        this.$refs.form.resetValidation(); // prevent validate error show again when click add
      }, 300);
    },
    saveZEEKInfo() {
      if (this.form_zeek_valid) {
        this.updateZEEKInfo(this.editedZEEK);
        this.update_zeek_close();
      }
    },
    positionChange() {
      const skillset = JSON.parse(process.env.VUE_APP_ZEEK_SKILLSET);

      if (this.editedZEEK.position_code === 'PMD') {
        this.defaultZEEK.skill_set = skillset;
      } else {
        let filterBRT = skillset.filter(function(obj) {
          return obj.name !== 'T1 - BRT';
        });
        this.defaultZEEK.skill_set = filterBRT;
      }
    },
    async changeImg(arr_index) {
      this.editedPayment.current_img = arr_index;
      const { path, upload_link } = await this.getUploadAWSUrl(
        this.editedPayment
      );
      this.editedPayment.image[arr_index].path = path;
      this.editedPayment.image[arr_index].url_upload = upload_link;
    },
    async checkTPCFR(item) {
      if (
        [
          parseInt(this.tpc_tier_id),
          parseInt(this.tpc2_tier_id),
          parseInt(this.tpcfl1_tier_id),
          parseInt(this.tpcfl2_tier_id),
        ].includes(item.employee_tier_id)
      ) {
        let rider_store = (await this.rb_stores[item.store_uid])
          ? await this.rb_stores[item.store_uid].business_unit_id
          : null;
        if (rider_store == null) return false;

        let bu_code = rider_store.toString();
        let bu_code_prefix = bu_code.substr(0, 2);

        return bu_code_prefix == '13' ? false : true;
      }
    },
    getAssignRiderLabel(item) {
      return item?.isReDispatch
        ? 'Re-Dispatch Rider Mobile No.'
        : 'Assign Rider Mobile No.';
    },
    async checkManualAssignRider(action = '', currentItem = null) {
      switch (action) {
        case 'Cancel':
          this.assign_rider_mobile_no = '';
          this.manual_assign_rider_dialog = false;
          await this.resetRiderManualAssign();
          break;
        case 'Edit':
          this.manual_assign_rider_dialog = false;
          await this.resetRiderManualAssign();
          this.$refs?.rider_mobile?.$el
            .querySelector('.v-input__slot input')
            ?.focus();
          break;
        case 'Assign': {
          this.current_assign_item = currentItem;
          if (this.riderAvailability && this.riderAvailability.is_warning) {
            this.manual_assign_rider_dialog = true;
          } else if (
            this.riderAvailability &&
            !this.riderAvailability.is_warning
          ) {
            this.manual_assign_rider_dialog = false;
            this.checkManualAssignRider('Confirm');
          } else {
            const Toast = this.$swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            Toast.fire({
              icon: 'error',
              title: 'Not found rider availability info please try again',
            });
          }

          break;
        }
        case 'Confirm': {
          let user_code;
          try {
            const order_no_parts = this.current_assign_item.order_no.split('_');
            if (!order_no_parts[1]) {
              throw new Error('Invalid order number format');
            }
            user_code = order_no_parts[1].slice(-4);
          } catch (error) {
            console.error('Error extracting user code:', error);
            const Toast = this.$swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            Toast.fire({
              icon: 'error',
              title: 'Error processing order number',
            });
            return;
          }

          const assign_payload = {
            client_merchant_id: this.current_assign_item.store_alias_id,
            partner_phone: this.searchRiderInfo.phone,
            user_code: user_code,
            webhook_order_id: this.current_assign_item.webhook_order_id,
          };

          await this.assignRider(assign_payload);
          this.manual_assign_rider_dialog = true;
          break;
        }
        case 'Done': {
          this.manual_assign_rider_dialog = false;
          this.assign_rider_mobile_no = '';
          await this.resetRiderManualAssign();

          this.setInitFilterOptions();
          this.initDatetime();
          break;
        }

        default: {
          if (this.assign_rider_mobile_no) {
            const mobileNo = this.assign_rider_mobile_no.replace(/-/g, '');

            await this.getCheckRiderWithPhoneNumber(mobileNo);
            if (this.searchRiderInfo) {
              this.assign_rider_name = `${this.searchRiderInfo.name_th ||
                '-'} ${this.searchRiderInfo.employee_no || '-'}`;

              await this.getCheckRiderAvailability(
                this.searchRiderInfo.employee_no
              );
            }
          } else {
            const Toast = this.$swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            Toast.fire({
              icon: 'error',
              title: 'Please enter rider mobile number',
            });
          }

          break;
        }
      }
    },
    async getManualAssignStoreList() {
      try {
        const manualAssignStoreList = JSON.parse(
          process.env.VUE_APP_MANUAL_ASSIGN_STORE_LIST
        );
        this.manual_assign_store_list = manualAssignStoreList;
      } catch (error) {
        console.error('Error parsing manual assign store list:', error);
        this.manual_assign_store_list = [];
      }
    },
  },
  computed: {
    ...mapGetters([
      'canDo',
      'tripsReport',
      'loadingDataTable',
      'lnHookStatus',
      'orderTopupTransactionTypes',
      'allowSearch',
      'isCheckRiderPhoneLoading',
      'isAssignRiderLoading',
      'searchRiderInfo',
      'riderAvailability',
      'assignRiderResponse',
    ]),
    headers() {
      var columns = [
        { text: 'Order No.', value: 'order_no', width: '100px' },
        { text: 'Fleet', value: 'fleet_name', width: '70px' },
        { text: 'Trip No.', value: 'trip_name', width: '70px' },
        // {text: "Brand", value: 'brand_alias'},
        {
          text: 'Order Store',
          value: 'store.store_alias_id',
          sortable: false,
          align: 'center',
          width: '80px',
        },
        {
          text: 'Driver Store',
          value: 'employee_store.store_alias_id',
          width: '80px',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Driver Tier',
          value: 'employee_tier_name',
          width: '80px',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Driver / Phone',
          value: 'employee_name',
          width: '80px',
          sortable: false,
          align: 'center',
        },
        // { text: "Phone No.", value: 'employee_phone', width: '110px', sortable: false, align: 'center' }, moved to driver
        {
          text: 'Payment Type',
          value: 'payment_method',
          width: '70px',
          align: 'center',
        },
        {
          text: 'Order Status',
          value: 'latest_ln_order_status',
          align: 'center',
          width: '100px',
        },
        // {text: "Gross Amount (Bht)", value: 'gross_amount' , align: 'right'},
        // {text: "Discount Amount (Bht)", value: 'discount_amount' , align: 'right'},
        {
          text: 'Distance (M)',
          value: 'distance',
          align: 'right',
          width: '65px',
        },
        {
          text: 'Total Amount (Bht)',
          value: 'total_amount',
          align: 'right',
          width: '75px',
        },
        {
          text: 'Delivery Fee (Bht)',
          value: 'delivery_fee',
          align: 'right',
          width: '75px',
        },
        // {text: "Driver Delivery Fee (Bht)", value: 'driver_delivery_fee', align: 'right' },
        // {text: "Driver Getpaid Food (Bht)", value: 'driver_getpaid_food', align: 'right' },
        // {text: "Driver Getpaid Delivery (Bht)", value: 'driver_getpaid_delivery', align: 'right' },
        // {text: "Commission (Bht)", value: 'commission' , align: 'right'},
        // {text: "Topup Food (Bht)", value: 'topup_food', align: 'right' },
        // {text: "Topup Fee (Bht)", value: 'topup_delivery', align: 'right' },
        { text: 'Created', value: 'order_created_at', width: '80px' },
        { text: 'Hooked At', value: 'webhook_updated_at', width: '80px' },
        {
          text: '',
          value: 'action',
          sortable: false,
          align: 'center',
          width: '50px',
        },
        { text: '', value: 'data-table-expand' },
      ];
      if (this.canDo && this.canDo.can.includes('manual_order_topup')) {
        return columns;
      } else {
        return columns.filter((column) => column.value != 'action');
      }
    },
    dataLisings() {
      var res = this.tripsReport ? this.tripsReport.data : [];
      return res;
    },
    totalResult() {
      return (this.tripsReport ? this.tripsReport.count : null) || 0;
    },
    filter_date_formatted() {
      if (!this.options.filter.date_range) return '';

      var date_format = this.options.filter.date_range.map((x) =>
        moment(x).format('DD-MM-YYYY')
      );
      return typeof date_format == 'object' ? date_format.join(' - ') : '';
    },
    filter_round_time() {
      // if(!this.options.filter.datetime_range)
      // return '';

      // var date_format = this.options.filter.datetime_range.map(x => moment(x).format('DD-MM-YYYY'));
      // return typeof date_format == 'object' ? date_format.join(' - ') : '';
      return [
        moment()
          .endOf('hour')
          .format('DD-MM-YYYY HH:mm'),
        moment()
          .endOf('hour')
          .format('DD-MM-YYYY HH:mm'),
      ];
    },
    selectedAll() {
      return this.lnHookStatus
        ? this.options.filter.ln_status.length === this.lnHookStatus.length
        : false;
    },
    selectedAllEmtier() {
      return this.op_employee_tier_id.length > 0
        ? this.options.filter.employee_tier_id.length ===
            this.op_employee_tier_id.length
        : false;
    },
    selectedSomeEmtier() {
      return (
        this.options.filter.employee_tier_id.length > 0 &&
        !this.selectedAllEmtier
      );
    },
    selectedSome() {
      return this.options.filter.ln_status.length > 0 && !this.selectedAll;
    },
    selectall_icon() {
      if (this.selectedAll) return 'mdi-close-box';
      if (this.selectedSome) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    selectall_emtier_icon() {
      if (this.selectedAllEmtier) return 'mdi-close-box';
      if (this.selectedSomeEmtier) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },
  async beforeMount() {
    await this.prepareStoreData();
    this.tpc_tier_id = await this.getTPCTierId();
    this.tpc2_tier_id = await this.getTPC2TierId();
    this.tpcfl1_tier_id = await this.getTPCFL1TierId();
    this.tpcfl2_tier_id = await this.getTPCFL2TierId();
  },
  mounted() {
    this.genCan();
    this.initDatetime();
    this.getManualAssignStoreList();
    this.user_type = localStorage.getItem('auth_type');
  },
  // mounted () {
  // }
};
</script>
<style>
@media (min-width: 1024px) and (max-width: 1200px) {
  .trips table {
    max-width: 100%;
    table-layout: fixed;
  }
  .trips {
    text-wrap: warp;
    white-space: normal;
    word-wrap: break-word;
  }
}

.v-text-field fieldset,
.v-text-field .v-input__control,
.v-text-field .v-input__slot {
  display: flex;
}

.v-data-table {
  max-width: 100%;
  min-width: 0;
}

.v-data-table table {
  max-width: 100%;
}

.v-data-table__wrapper {
  max-width: 100%;
}

.v-data-table.text-small td {
  font-size: 11px !important;
  height: 40px !important;
}

.expand-info {
  padding: 10px 20px;
}

.expand-info div {
  display: table-row;
}

.expand-info div span {
  display: table-cell;
}

.info-title {
  padding-right: 30px;
}

.info-detail {
  text-align: right;
  vertical-align: middle;
}

.info-detail.success {
  color: #388e3c;
  background: none;
  vertical-align: middle;
}

.custom-table1 {
  border-bottom: 1px solid #ccc;
}

.custom-table1 th {
  background: #eee;
  height: auto;
  border-bottom: none !important;
}

.v-data-table.text-small .custom-table1 td {
  height: auto !important;
  padding: 3px 20px !important;
  border-bottom: 1px solid #ccc;
}
</style>
